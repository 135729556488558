import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import debounce from "../utils/debounce";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        column: "[data-content-section-column]",
        stickers: "[data-content-section-sticker]",
        teaser: "[data-content-section-teaser]",
    },
    columns: null,
    tween: [],
    stickers: null,

    init() {
        this.columns = Array.from(
            this.$el.querySelectorAll(this.selectors.column),
        );
        this.stickers = Array.from(
            this.$el.querySelectorAll(this.selectors.stickers),
        );

        this.$nextTick(() => {
            this.startScrollTrigger();
        });

        this.debouncedResize = this.onResize.bind(this);
        this.handleResize = debounce(this.debouncedResize);
        window.addEventListener("resize", this.handleResize);
    },

    onResize() {
        if (this.tween && this.tween.length) {
            this.tween.forEach((el) => {
                el.kill();
            });

            gsap.set(this.columns, {
                clearProps: "translate,transform,rotate,scale",
            });
            gsap.set(this.stickers, {
                clearProps: "translate,transform,rotate,scale",
            });

            this.tween = [];
        }

        this.$nextTick(() => {
            this.startScrollTrigger();
        });
    },

    startScrollTrigger() {
        let mm = gsap.matchMedia();

        mm.add("(min-width: 1024px)", () => {
            this.tween.push(
                ScrollTrigger.create({
                    trigger: this.$el,
                    start: "top top",
                    end: "bottom bottom",
                    markers: false,
                    onToggle: (self) => {
                        if (self.isActive) {
                            console.log("active");
                        }
                    },
                }),
            );

            this.stickers.forEach((el) => {
                this.tween.push(
                    gsap.from(el, {
                        scale: 0.5,
                        scrollTrigger: {
                            trigger: el.closest(this.selectors.column),
                            endTrigger: el,
                            start: "top bottom",
                            end: "middle middle",
                            scrub: 2,
                            markers: false,
                        },
                    }),
                );
            });

            this.columns.forEach((el) => {
                const elHeight = el.offsetHeight;
                const parentHeight = el.parentElement.offsetHeight;
                const marginNotToMove = 50;

                if (elHeight + marginNotToMove < parentHeight) {
                    this.tween.push(
                        gsap.to(el, {
                            y: parentHeight - elHeight,
                            scrollTrigger: {
                                trigger: el,
                                endTrigger: el.parentElement,
                                start: "top top+=10%",
                                end: "bottom top",
                                scrub: 2,
                                markers: false,
                            },
                        }),
                    );
                }
            });
        });
    },

    destroy() {
        window.removeEventListener("resize", this.handleResize);
    },

    setScene() {},
});
