import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import debounce from "../utils/debounce";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        teaser: "[data-content-section-teaser]",
    },
    tween: [],
    teaser: null,

    init() {
        this.teaser = Array.from(
            this.$el.querySelectorAll(this.selectors.teaser),
        );

        this.$nextTick(() => {
            this.startScrollTrigger();
        });
    },

    onResize() {
        if (this.tween && this.tween.length) {
            this.tween.forEach((el) => {
                el.kill();
            });

            gsap.set(this.teaser, {
                clearProps: "translate,transform,rotate,scale",
            });

            this.tween = [];
        }

        this.$nextTick(() => {
            this.startScrollTrigger();
        });
    },

    startScrollTrigger() {
        // let mm = gsap.matchMedia();

        // mm.add("(min-width: 1024px)", () => {
        this.teaser.forEach((el) => {
            this.tween.push(
                gsap.to(el, {
                    y: this.isMedium ? 150 : 50,
                    scrollTrigger: {
                        trigger: this.$el,
                        start: "top top",
                        end: "bottom top",
                        scrub: 2,
                    },
                }),
            );
        });
        // });
    },

    get isMedium() {
        return this.$store.breakpoint.md;
    },
});
