import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default (index, storyId, buildingKey) => ({
    mouseMovePercent: 0,
    imageMove: null,
    index,
    storyId,
    buildingKey,
    isClicked: false,
    isOpen: false,
    isStoryDisabled: false,
    gsapInstances: [],
    transitionsSmall: [
        { from: { x: "-8%" }, to: { x: "8%" } },
        { from: { x: "8%" }, to: { x: "-8%" } },
        { from: { y: "-8%" }, to: { y: "8%" } },
        { from: { y: "8%" }, to: { y: "-8%" } },
        { from: { scale: 1.2 }, to: { scale: 1 } },
        { from: { scale: 1 }, to: { scale: 1.2 } },
    ],
    transitionsBig: [
        { from: { x: "-8%" }, to: { x: "8%" } },
        { from: { x: "8%" }, to: { x: "-8%" } },
        { from: { y: "-8%" }, to: { y: "8%" } },
        { from: { y: "8%" }, to: { y: "-8%" } },
        { from: { scale: 1.2 }, to: { scale: 1 } },
        { from: { scale: 1 }, to: { scale: 1.2 } },
    ],
    path: {
        inPath: [],
        boxes: [],
        inPathMiddle: [],
        outPath: [],
        yCounter: 0,
        xCounter: 0,
        xWidth: 43.31,
        yWidth: 43.52,
        slashWidth: 8.56,
        slashWidth0: 0,
    },
    init() {
        this.initScene();
        this.createFilterSVG();

        this.boundMouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.boundMouseLeaveHandler = this.mouseMoveLeave.bind(this);
        this.$refs.image.addEventListener(
            "mousemove",
            this.boundMouseMoveHandler,
        );

        this.$refs.image.addEventListener(
            "mouseleave",
            this.boundMouseLeaveHandler,
        );
    },

    allStoryTransitionComplete() {
        ScrollTrigger.refresh();
    },

    onResize() {
        this.regenerateFilterSvg();
    },

    destroy() {
        this.$refs.image.removeEventListener(
            "mousemove",
            this.boundMouseMoveHandler,
        );
    },

    mouseMoveLeave() {
        if (this.imageMove) {
            this.imageMove.pause();
        }

        if (this.imageMove2) {
            this.imageMove2.pause();
        }

        gsap.to([this.$refs.image, this.$refs.imageWrapper], {
            y: 0,
            x: 0,
            duration: 0.5,
            ease: "power2.out",
        });
    },

    mouseMoveHandler(event) {
        if (this.isTouchDevice()) return;

        // Get the bounding rectangle of the element
        const rect = this.$refs.image.getBoundingClientRect();

        // Calculate the mouse's Y position relative to the element's top edge
        const mouseY = event.clientY - rect.top;
        const mouseX = event.clientX - rect.left;

        // Normalize the value to get -50% at the top, 0% in the middle, and 50% at the bottom
        const percentY = (mouseY / rect.height - 0.5) * 60;
        const percentX = (mouseX / rect.width - 0.5) * -rect.width * 0.1;

        const imageY = (mouseY / rect.height - 0.5) * 80;
        const imageX = (mouseX / rect.width - 0.5) * 80;

        gsap.to(this.$refs.linkWrapper, {
            y: `${percentY}%`,
            x: `${percentX}px`,
            duration: 2,
            ease: "power2.out",
        });

        if (this.imageMove) {
            this.imageMove.pause();
        }

        if (this.imageMove2) {
            this.imageMove2.pause();
        }

        this.imageMove = gsap.to(this.$refs.image, {
            y: `${imageY}px`,
            x: `${imageX}px`,
            duration: 1.5,
            ease: "power2.out",
        });

        this.imageMove2 = gsap.to(this.$refs.imageWrapper, {
            y: `${-imageY * 0.8}px`,
            x: `${-imageX * 0.8}px`,
            duration: 1.5,
            ease: "power2.out",
        });
    },

    initScene() {
        // let mm = gsap.matchMedia();

        gsap.set(this.$refs.imageInner, { scale: 1.2 });
        const odd = this.$refs.imageInner.hasAttribute("data-odd");
        const big = this.$refs.imageInner.hasAttribute("data-big");
        let transition;
        if (big) {
            transition =
                this.transitionsBig[this.index % this.transitionsBig.length];
        } else {
            transition =
                this.transitionsSmall[
                    this.index % this.transitionsSmall.length
                ];
        }

        // mm.add("(min-width: 640px)", () => {
        gsap.fromTo(this.$refs.imageInner, transition.from, {
            scrollTrigger: {
                trigger: this.$el,
                start: "top 80%",
                end: "bottom top",
                scrub: 1 + Math.random() * 2,
            },
            ...transition.to,
        });

        const from = big ? { y: 150 } : odd ? { x: -150 } : { x: 150 };
        const to = big ? { y: 0 } : { x: 0 };

        gsap.fromTo(
            this.$el,
            { opacity: 0, ...from },
            {
                opacity: 1,
                ...to,
                duration: 1,
                scrollTrigger: {
                    trigger: this.$el,
                    start: "top bottom",
                    end: "middle bottom",
                    scrub: 1 + Math.random() * 10,
                    once: true,
                },
            },
        );
        // });
    },

    disableStory(event) {
        const { disable, index } = event.detail;

        if (!disable) {
            this.isStoryDisabled = false;
            return;
        }

        if (disable && this.index !== index) {
            this.isStoryDisabled = true;
            this.isClicked = false;
        }
    },

    clickOutside() {
        if (!this.isClicked) return;

        this.isStoryDisabled = false;
        this.isClicked = false;
        this.$dispatch("disable-other-stories", {
            disable: false,
        });
    },

    openStoryByKey(event) {
        const { key } = event.detail;
        if (!key || this.isOpen) return;

        if (key === this.buildingKey) {
            this.openStory();
        }
    },

    openStory() {
        if (!this.isTouchDevice() || this.isClicked) {
            this.callForStory();
            this.$dispatch("disable-other-stories", {
                index: this.index,
                disable: false,
            });
            this.isClicked = false;
        } else {
            this.$dispatch("disable-other-stories", {
                index: this.index,
                disable: true,
            });
            this.isClicked = true;
            this.isStoryDisabled = false;
        }
    },

    getFirstNextSiblingWithAttribute(element, attributeName) {
        // Start with the next sibling element
        let sibling = element.nextElementSibling;

        // Loop through all next siblings
        while (sibling) {
            // Check if the sibling has the specified attribute
            if (sibling.hasAttribute(attributeName)) {
                return sibling; // Return the sibling if attribute is found
            }
            // Move to the next sibling
            sibling = sibling.nextElementSibling;
        }

        // Return null if no sibling with the attribute is found
        return null;
    },

    closeStory(event) {
        const { currentOpenIndex } = event.detail;

        if (currentOpenIndex === this.index || !this.isOpen) {
            return;
        }

        this.isOpen = false;
        const container = this.getFirstNextSiblingWithAttribute(
            this.$el.parentElement,
            "data-preview-accordion",
        );

        this.$dispatch("story-is-open", { isOpen: false });

        this.teaserInAnimation();
        this.$dispatch("close-modal", { container, index: this.index });
    },

    teaserInAnimation() {
        const inPathDom = document.querySelectorAll(
            `#clip-path-${this.index} path`,
        );

        gsap.timeline({
            onComplete: () => {
                gsap.set(this.$el, { clearProps: "clipPath,pointerEvents" });
            },
        })
            .set(this.$el, {
                clipPath: `url(#clip-path-${this.index})`,
            })
            .set(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.path.inPath[i].d,
                    };
                },
            })
            .to(this.$el.parentElement, {
                height: "auto",
                duration: 0.3,
                ease: "power2.inOut",
            })
            .to(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.path.inPathMiddle[i].d,
                    };
                },
                opacity: 1,
                ease: "Power2.easeOut",
                duration: 0.3,
                stagger: {
                    amount: 0.6,
                    grid: [this.path.yCounter, this.path.xCounter],
                    from: [0, 1],
                },
            })
            .to(
                inPathDom,
                {
                    attr: (i) => {
                        return {
                            d: this.path.outPath[i].d,
                        };
                    },
                    opacity: 1,
                    ease: "Power2.easeOut",
                    duration: 0.3,
                    stagger: {
                        amount: 0.8,
                        grid: [this.path.yCounter, this.path.xCounter],
                        from: [0, 1],
                    },
                },
                "<10%",
            );
    },

    teaserOutAnimation() {
        if (this.gsapLoading) {
            this.gsapLoading.pause();
        }

        gsap.set(this.$el, { pointerEvents: "none" });

        const inPathDom = document.querySelectorAll(
            `#clip-path-${this.index} path`,
        );

        gsap.timeline()
            .set(this.$el, {
                clipPath: `url(#clip-path-${this.index})`,
                delay: 0.2,
            })
            .set(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.path.outPath[i].d,
                    };
                },
            })
            .to(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.path.inPathMiddle[i].d,
                    };
                },
                opacity: 1,
                ease: "Power2.easeOut",
                duration: 0.3,
                stagger: {
                    amount: 0.6,
                    grid: [this.path.yCounter, this.path.xCounter],
                    from: [1, 0],
                },
            })
            .to(
                inPathDom,
                {
                    attr: (i) => {
                        return {
                            d: this.path.inPath[i].d,
                        };
                    },
                    opacity: 1,
                    ease: "Power2.easeOut",
                    duration: 0.3,
                    stagger: {
                        amount: 0.8,
                        grid: [this.path.yCounter, this.path.xCounter],
                        from: [1, 0],
                    },
                },
                "<10%",
            )
            .to(
                this.$el.parentElement,
                {
                    height: 0,
                    duration: 0.2,
                    ease: "power3.inOut",
                },
                "<80%",
            );
    },

    loadingAnimation() {
        gsap.set(this.$el, { pointerEvents: "none" });

        const inPathDom = document.querySelectorAll(
            `#clip-path-${this.index} path`,
        );

        this.gsapLoading = gsap
            .timeline({
                yoyo: true,
                repeat: -1,
            })
            .set(this.$el, {
                clipPath: `url(#clip-path-${this.index})`,
                delay: 0.2,
            })
            .set(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.path.outPath[i].d,
                    };
                },
            })
            .to(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.path.inPathMiddle[i].d,
                    };
                },
                opacity: 1,
                ease: "Power2.easeOut",
                duration: 0.3,
                stagger: {
                    amount: 0.6,
                    grid: [this.path.yCounter, this.path.xCounter],
                    from: [1, 0],
                },
            });
    },

    callForStory() {
        const container = this.getFirstNextSiblingWithAttribute(
            this.$el.parentElement,
            "data-preview-accordion",
        );
        this.loadingAnimation();

        fetch(`stories/render/${this.storyId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.text())
            .then((html) => {
                this.isOpen = true;
                this.teaserOutAnimation();

                this.$dispatch("open-modal", {
                    container,
                    html,
                    currentOpenIndex: this.index,
                    buildingKey: this.buildingKey,
                });
                this.$dispatch("close-story", { currentOpenIndex: this.index });
                this.$dispatch("story-is-open", { isOpen: true });
            })
            .catch((error) => {
                console.error("Error while loading story:", error);
            });
    },

    regenerateFilterSvg() {
        this.$refs.patternDef.innerHTML = "";
        this.path.inPath = [];
        this.path.inPathMiddle = [];
        this.path.outPath = [];
        this.createFilterSVG();
    },

    createFilterSVG() {
        const { width, height } = this.$el.getBoundingClientRect();
        const correctedWidth = width * 2;
        this.path.xCounter = Math.ceil(correctedWidth / this.path.xWidth) + 1;
        this.path.yCounter = Math.ceil(height / this.path.yWidth);

        if (this.path.xCounter > 32 || this.path.yCounter > 32) {
            const newWidth = correctedWidth / 32; // max number of paths in row
            const scale = newWidth / this.path.xWidth;

            this.path.xWidth = newWidth;
            this.path.yWidth *= scale;
            this.path.slashWidth *= scale;

            this.path.xCounter =
                Math.ceil(correctedWidth / this.path.xWidth) + 1;
            this.path.yCounter = Math.ceil(height / this.path.yWidth);
        }

        for (let row = 0; row < this.path.yCounter; row++) {
            for (let x = 0; x < this.path.xCounter; x++) {
                const tempPathIn = `M${(x + 1) * this.path.xWidth - this.path.slashWidth} ${row * this.path.yWidth} H${(x + 1) * this.path.xWidth} L${
                    x * this.path.xWidth + this.path.slashWidth
                } ${this.path.yWidth * (row + 1)} H${x * this.path.xWidth} L${(x + 1) * this.path.xWidth - this.path.slashWidth} ${row * this.path.yWidth}Z `;

                const tempPathIn0 = `M${(x + 1) * this.path.xWidth - this.path.slashWidth0} ${row * this.path.yWidth} H${(x + 1) * this.path.xWidth} L${
                    x * this.path.xWidth + this.path.slashWidth0
                } ${this.path.yWidth * (row + 1)} H${x * this.path.xWidth} L${(x + 1) * this.path.xWidth - this.path.slashWidth0} ${row * this.path.yWidth}Z `;

                const tempPathOut = `
                  M${x * this.path.xWidth - this.path.slashWidth} ${row * this.path.yWidth}
                  H${(x + 1) * this.path.xWidth}
                  L${x * this.path.xWidth + this.path.slashWidth} ${this.path.yWidth * (row + 1) + 1}
                  H${(x - 1) * this.path.xWidth}
                  L${x * this.path.xWidth - this.path.slashWidth} ${row * this.path.yWidth}Z `;

                this.path.inPath.push({ d: tempPathIn0 });
                this.path.inPathMiddle.push({ d: tempPathIn });
                this.path.outPath.push({ d: tempPathOut });
            }
        }

        const localInPath = [...this.path.inPath];
        const clipPath = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "clipPath",
        );

        clipPath.setAttribute("id", `clip-path-${this.index}`);

        localInPath.map((path) => {
            const newPathFrom = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path",
            );
            newPathFrom.setAttribute("d", path.d);
            clipPath.appendChild(newPathFrom);
        });

        this.$refs.patternDef.appendChild(clipPath);
    },

    isTouchDevice() {
        return (
            "ontouchstart" in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
        );
    },
});
