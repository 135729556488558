import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export default () => ({
    mouseMovePercent: 0,
    images: null,
    closingModalHeight: 0,
    openStoryScrollTrigger: null,
    path: {
        inPath: [],
        boxes: [],
        inPathMiddle: [],
        outPath: [],
        yCounter: 0,
        xCounter: 0,
        xWidth: 80.31,
        yWidth: 80.52,
        slashWidth: 28.56,
        slashWidth0: 0,
    },

    init() {
        this.images = this.$el.querySelectorAll("[data-story-list-image]");
        this.createFilterSVG();
    },

    destroy() {
        this.$refs.image.removeEventListener(
            "mousemove",
            this.boundMouseMoveHandler,
        );
    },

    onResize() {
        this.regenerateFilterSvg();
    },

    openModal(event) {
        const { container, html, currentOpenIndex, buildingKey } = event.detail;
        const inPathDom = document.querySelectorAll(`#story-clip-path path`);
        container.setAttribute("data-story-accordion-is-opening", "");

        const tl = gsap.timeline({
            onComplete: () => {
                this.isOpen = true;
                gsap.set(this.$el, { clearProps: "clipPath,pointerEvents" });
                gsap.set(container, { clearProps: "clipPath" });
                container.removeAttribute("data-story-accordion-is-opening");
                container.setAttribute("data-story-accordion-opened", "");

                this.$dispatch("story-transition-end", {
                    index: currentOpenIndex,
                });
            },
        });

        tl.set(
            container,
            {
                clipPath: `url(#story-clip-path)`,
            },
            "<",
        );

        // if container is already opened lets mask it before content has been updated
        if (container.hasAttribute("data-story-accordion-opened")) {
            tl.to(inPathDom, {
                duration: 0,
                attr: (i) => {
                    return {
                        d: this.path.outPath[i].d,
                    };
                },
            });

            tl.to(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.path.inPathMiddle[i].d,
                    };
                },
                opacity: 1,
                ease: "Power2.easeOut",
                duration: 0.2,
                stagger: {
                    amount: 0.3,
                    grid: [this.path.yCounter, this.path.xCounter],
                    from: [1, 0],
                },
            }).to(
                inPathDom,
                {
                    attr: (i) => {
                        return {
                            d: this.path.inPath[i].d,
                        };
                    },
                    opacity: 1,
                    ease: "Power2.easeOut",
                    duration: 0.2,
                    stagger: {
                        amount: 0.4,
                        grid: [this.path.yCounter, this.path.xCounter],
                        from: [1, 0],
                    },
                    onComplete: () => {
                        container.innerHTML = html;
                    },
                },
                "<10%",
            );
        } else {
            container.innerHTML = html;
        }

        // if container is not opened lets expand it to auto height from 0
        if (!container.hasAttribute("data-story-accordion-opened")) {
            tl.fromTo(
                container,
                {
                    height: 0,
                },
                {
                    height: "auto",
                    duration: 0.7,
                    ease: "power2.in",
                    onComplete: () => {
                        this.createOpenStoryScrollTrigger(
                            container,
                            buildingKey,
                        );
                    },
                },
            );
        } else {
            tl.set(container, {
                height: "auto",
                onComplete: () => {
                    this.createOpenStoryScrollTrigger(container, buildingKey);
                },
            });
        }

        // lets reveal the content with the mask
        tl.to(
            inPathDom,
            {
                duration: 0,
                attr: (i) => {
                    return {
                        d: this.path.inPath[i].d,
                    };
                },
            },
            container.hasAttribute("data-story-accordion-opened") ? null : "<",
        );

        tl.to(inPathDom, {
            attr: (i) => {
                return {
                    d: this.path.inPathMiddle[i].d,
                };
            },
            opacity: 1,
            ease: "Power2.easeOut",
            duration: 0.3,
            stagger: {
                amount: 0.6,
                grid: [this.path.yCounter, this.path.xCounter],
                from: [1, 0],
            },
        });
        tl.to(
            window,
            {
                duration: 0.5,
                scrollTo: container,
                onComplete: () => {
                    ScrollTrigger.refresh();
                },
            },
            "<",
        )

            .to(
                inPathDom,
                {
                    attr: (i) => {
                        return {
                            d: this.path.outPath[i].d,
                        };
                    },
                    opacity: 1,
                    ease: "Power2.easeOut",
                    duration: 0.3,
                    stagger: {
                        amount: 0.8,
                        grid: [this.path.yCounter, this.path.xCounter],
                        from: [1, 0],
                    },
                },
                "<10%",
            );
    },

    createOpenStoryScrollTrigger(container, buildingKey) {
        if (this.openStoryScrollTrigger) {
            this.openStoryScrollTrigger.kill();
        }

        this.openStoryScrollTrigger = ScrollTrigger.create({
            trigger: container,
            start: "top bottom-=200",
            end: "bottom bottom-=160",
            onToggle: ({ isActive }) => {
                this.$dispatch("story-accordion-state", {
                    isOpen: isActive,
                    buildingKey,
                });
            },
        });
    },

    closeModal(event) {
        const { container, index } = event.detail;
        const isAnyStoryIsOpening = document.querySelectorAll(
            "[data-story-accordion-is-opening]",
        );
        const scrollTop = window.scrollY;
        const heightDifference = container.clientHeight;
        this.closingModalHeight = heightDifference;

        if (!container.hasAttribute("data-story-accordion-is-opening")) {
            if (!isAnyStoryIsOpening.length) {
                gsap.to(window, {
                    duration: 0.3,
                    scrollTo: { y: this.$el, offsetY: 50 },
                    onProgress: () => {
                        ScrollTrigger.refresh();
                    },
                });
            } else {
                gsap.to(window, {
                    duration: 0.3,
                    scrollTo: scrollTop - heightDifference,
                });
            }

            gsap.to(container, {
                height: 0,
                duration: 0.3, //isAnyStoryIsOpening.length ? 0 : 0.3,
                onComplete: () => {
                    // container.innerHTML = "";
                    container.removeAttribute(
                        "data-story-accordion-opened",
                        "",
                    );
                    this.$dispatch("story-transition-end", { index });
                    if (!isAnyStoryIsOpening.length) {
                        this.$dispatch("story-accordion-state", {
                            isOpen: false,
                        });
                    }
                },
            });
        }
    },

    regenerateFilterSvg() {
        this.$refs.patternDef.innerHTML = "";
        this.path.inPath = [];
        this.path.inPathMiddle = [];
        this.path.outPath = [];
        this.createFilterSVG();
    },

    createFilterSVG() {
        this.path.xCounter =
            Math.ceil(window.innerWidth / this.path.xWidth) + 1;
        this.path.yCounter = Math.ceil(window.innerHeight / this.path.yWidth);

        if (this.path.xCounter > 32 || this.path.yCounter > 32) {
            const newWidth = window.innerWidth / 32; // max number of paths in row
            const scale = newWidth / this.path.xWidth;

            this.path.xWidth = newWidth;
            this.path.yWidth *= scale;
            this.path.slashWidth *= scale;

            this.path.xCounter =
                Math.ceil(window.innerWidth / this.path.xWidth) + 1;
            this.path.yCounter = Math.ceil(
                window.innerHeight / this.path.yWidth,
            );
        }

        for (let row = 0; row < this.path.yCounter; row++) {
            for (let x = 0; x < this.path.xCounter; x++) {
                const tempPathIn = `M${(x + 1) * this.path.xWidth - this.path.slashWidth} ${row * this.path.yWidth} H${(x + 1) * this.path.xWidth} L${
                    x * this.path.xWidth + this.path.slashWidth
                } ${this.path.yWidth * (row + 1)} H${x * this.path.xWidth} L${(x + 1) * this.path.xWidth - this.path.slashWidth} ${row * this.path.yWidth}Z `;

                const tempPathIn0 = `M${(x + 1) * this.path.xWidth - this.path.slashWidth0} ${row * this.path.yWidth} H${(x + 1) * this.path.xWidth} L${
                    x * this.path.xWidth + this.path.slashWidth0
                } ${this.path.yWidth * (row + 1)} H${x * this.path.xWidth} L${(x + 1) * this.path.xWidth - this.path.slashWidth0} ${row * this.path.yWidth}Z `;

                const tempPathOut = `
                  M${x * this.path.xWidth - this.path.slashWidth} ${row * this.path.yWidth}
                  H${(x + 1) * this.path.xWidth}
                  L${x * this.path.xWidth + this.path.slashWidth} ${this.path.yWidth * (row + 1) + 1}
                  H${(x - 1) * this.path.xWidth}
                  L${x * this.path.xWidth - this.path.slashWidth} ${row * this.path.yWidth}Z `;

                this.path.inPath.push({ d: tempPathIn0 });
                this.path.inPathMiddle.push({ d: tempPathIn });
                this.path.outPath.push({ d: tempPathOut });
            }
        }

        const localInPath = [...this.path.inPath];
        const clipPath = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "clipPath",
        );

        clipPath.setAttribute("id", "story-clip-path");

        localInPath.map((path) => {
            const newPathFrom = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path",
            );
            newPathFrom.setAttribute("d", path.d);
            clipPath.appendChild(newPathFrom);
        });

        this.$refs.patternDef.appendChild(clipPath);
    },

    mouseMoveHandler(event) {
        // Get the bounding rectangle of the element
        const rect = this.$el.getBoundingClientRect();

        // Calculate the mouse's Y position relative to the element's top edge
        const mouseY = event.clientY - rect.top;
        const mouseX = event.clientX + 2 * rect.left;

        // Normalize the value to get -50% at the top, 0% in the middle, and 50% at the bottom
        const percentY = (mouseY / window.innerHeight - 0.5) * 40;
        const percentX = (mouseX / window.innerWidth - 0.5) * 40;

        gsap.to(this.images, {
            y: `${percentY}px`,
            x: `${percentX}px`,
            duration: 1,
            stagger: 0.2,
            ease: "power2.out",
        });
    },
});
