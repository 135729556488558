import { Viewer } from "../utils/webglViewer.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Flip);

export default (linksList) => {
    const webGlFile = "/dist/3d/model/IPZ_webgl_16.glb";
    let minWebglViewer;
    return {
        showTeaser: false,
        isModelLoaded: false,
        showWebGL: false,
        videosPlayer: [],
        webglOuterState: false,
        isWebGlSticked: false,
        isFullScreen: false,
        currentBuilding: null,
        linksArray: null,
        activeBuildingKey: null,
        buildingsBase: [
            {
                key: "space-hub",
                name: "Space Hub",
                camera: {
                    x: -375.32544870271227,
                    y: 56.654807556180415,
                    z: 204.8342473475318,
                },
                position: {
                    x: 147.313575,
                    y: -113.781089,
                    z: 120.60919600000001,
                },
            },
            {
                key: "buro-zuri",
                name: "Büro Züri",
                camera: {
                    x: -326.17333535893727,
                    y: 44.876941011987796,
                    z: 144.35697579718163,
                },
                position: {
                    x: 144.51010827719628,
                    y: -107.37300568889752,
                    z: 86.46146144465149,
                },
            },
            {
                key: "hq-ipz",
                name: "HQ IPZ",
                camera: {
                    x: -290.2381364168703,
                    y: 33.77963568648644,
                    z: 121.98469516896276,
                },
                position: {
                    x: 117.58675522262526,
                    y: -105.91167837288143,
                    z: -25.997148395108248,
                },
            },
            {
                key: "startbahn-29",
                name: "Startbahn 29",
                camera: {
                    x: -288.80639905605295,
                    y: 74.34875944580185,
                    z: 107.9403499826641,
                },
                position: {
                    x: 14.797671887597412,
                    y: -239.27382596385618,
                    z: -5.990186782031545,
                },
            },
            {
                key: "angst-pfister",
                name: "Angst & Pfister",
                camera: {
                    x: -196.43791682087533,
                    y: 22.2929196894313,
                    z: 128.77515054996752,
                },
                position: { x: -93.720692, y: -113.411967, z: -167.647317 },
            },
            {
                key: "cellsius",
                name: "Cellsius",
                camera: {
                    x: -172.12102848648482,
                    y: 16.36703458885853,
                    z: 111.35948398096284,
                },
                position: {
                    x: -89.71692099999999,
                    y: -134.635293,
                    z: -175.22209999999998,
                },
            },
            {
                key: "aris",
                name: "ARIS",
                camera: {
                    x: -100.39577969139219,
                    y: 16.76850081885854,
                    z: 82.3202909445627,
                },
                position: {
                    x: -248.24235599999994,
                    y: -119.137788,
                    z: -126.86135,
                },
            },
            {
                key: "amz",
                name: "AMZ",
                camera: {
                    x: -65.0237052434274,
                    y: 22.478623565422183,
                    z: 60.82335253895627,
                },
                position: {
                    x: -247.753476,
                    y: -121.661421,
                    z: -125.480434,
                },
            },
            {
                key: "swissloop",
                name: "Swissloop",
                camera: {
                    x: -36.76623765848535,
                    y: 22.269146000000177,
                    z: 37.06188103488523,
                },
                position: {
                    x: -214.034796,
                    y: -119.489597,
                    z: -156.214964,
                },
            },
            {
                key: "center-for-immersive-wave",
                name: "Center for Immersive Wave Experimentation",
                camera: {
                    x: -13.557424643462952,
                    y: 22.845690115108837,
                    z: 16.344560696036012,
                },
                position: {
                    x: -189.890972,
                    y: -115.78122799999998,
                    z: -178.199633,
                },
            },
            {
                key: "eth-hangar",
                name: "ETH Hangar",
                camera: {
                    x: 11.942134229582223,
                    y: 76.71438983133888,
                    z: 82.7713787357801,
                },
                position: {
                    x: -252.75741843942353,
                    y: -150.2561326123223,
                    z: -58.70287124716999,
                },
            },
            {
                key: "event-hangar",
                name: "Event Hangar",
                camera: {
                    x: 30.020681297426655,
                    y: 84.56439237028712,
                    z: 25.574249983279913,
                },
                position: {
                    x: 82.57285478943533,
                    y: -85.9653551832885,
                    z: -228.0764073052705,
                },
            },
        ],
        buildings: [],
        tween: [],
        buildingKeysRelations: [
            {
                key: "eth-hangar",
                children: ["cellsius", "aris", "amz", "swissloop"],
            },
        ],

        init() {
            this.linksArray = linksList;

            this.buildings = this.buildingsBase.map((v) => {
                const link = this.linksArray.find((l) => l.key === v.key);
                return {
                    ...v,
                    ...link,
                };
            });

            this.$nextTick(() => {
                this.startScrollTrigger();
            });

            this.options = {
                showGui: false,
                kiosk: false,
                model: "",
                preset: "",
                cameraPosition: [-301, 83, -343],
                objectPosition: [-125, -23, -236],
            };

            this.setWebGlView();
        },

        storyHpStateChange(event) {
            const { isOpen, buildingKey } = event.detail;
            this.showTeaser = isOpen;
            this.activeBuildingKey = buildingKey;

            if (isOpen) {
                this.navigateToBuilding(buildingKey);
            }
        },

        startScrollTrigger() {
            this.tween.push(
                gsap.fromTo(
                    this.$refs.copy,
                    { y: 100 },
                    {
                        y: -50,
                        scrollTrigger: {
                            trigger: this.$refs.copy,
                            start: "top bottom",
                            end: "bottom top",
                            scrub: 2,
                        },
                    },
                ),
            );
        },

        navigateToBuilding(buildingName) {
            const object = this.buildings.find((v) => v.key === buildingName);

            this.currentBuilding = object;

            this.playToBuildingAnimation();
        },

        goToNextBuilding() {
            const currentBuildingIndex =
                this.buildings.findIndex(
                    (v) => v.key === this.currentBuilding.key,
                ) || 0;
            const nextBuildingIndex =
                currentBuildingIndex + 1 >= this.buildings.length
                    ? 0
                    : currentBuildingIndex + 1;

            const nextBuilding = this.buildings[nextBuildingIndex];

            this.currentBuilding = nextBuilding;
            this.playToBuildingAnimation();
        },

        goToPrevBuilding() {
            const currentBuildingIndex =
                this.buildings.findIndex(
                    (v) => v.key === this.currentBuilding.key,
                ) || 0;
            const prevBuildingIndex =
                currentBuildingIndex - 1 < 0
                    ? this.buildings.length - 1
                    : currentBuildingIndex - 1;
            const prevBuilding = this.buildings[prevBuildingIndex];

            this.currentBuilding = prevBuilding;
            this.playToBuildingAnimation();
        },

        showFullScreen() {
            if (!minWebglViewer) return;
            this.isFullScreen = true;
            minWebglViewer.goToFullScreen();
        },

        closeFullWindow() {
            if (!minWebglViewer) return;
            this.isFullScreen = false;

            if (this.currentBuilding.kay !== this.activeBuildingKey) {
                this.navigateToBuilding(this.activeBuildingKey);
            }
            minWebglViewer.goToDefaultScreen();
        },

        goToStory() {
            if (!this.currentBuilding || !minWebglViewer || !this.isOpen)
                return;

            this.$dispatch("change-building", {
                key: this.currentBuilding.key,
            });

            this.closeFullWindow();
        },

        playToBuildingAnimation() {
            if (!this.currentBuilding || !minWebglViewer) return;

            const building = this.currentBuilding;

            const { x, y, z } = building.position;
            const {
                x: cameraX,
                y: cameraY,
                z: cameraZ,
            } = building.camera || building.position;

            const timeline = gsap.timeline();

            if (building.position) {
                timeline.to(minWebglViewer.controls.target, {
                    x,
                    y,
                    z,
                    duration: 2,
                    ease: "power1.inOut",
                });
            }

            if (building.camera) {
                timeline.to(
                    minWebglViewer.defaultCamera.position,
                    {
                        x: cameraX,
                        y: cameraY,
                        z: cameraZ,
                        duration: 2,
                        ease: "power1.inOut",
                        onStart: () => {
                            const mappedKeys = this.mapBuildingKeys(
                                building.key,
                            );
                            minWebglViewer.changeObjectColor(mappedKeys);
                        },
                    },
                    "<0%",
                );
            }
        },

        mapBuildingKeys(buildingKey) {
            return (
                this.buildingKeysRelations.find((v) => v.key === buildingKey)
                    ?.children || [buildingKey]
            );
        },

        setWebGlView() {
            if (minWebglViewer) {
                minWebglViewer.clear();
            }

            minWebglViewer = new Viewer(this.$refs.webgl2, this.options);

            minWebglViewer
                .load(webGlFile)
                .catch((e) => this.onError(e))
                .then(() => {
                    this.isModelLoaded = true;
                    // minWebglViewer.changeObjectColor();
                });
        },

        onError(error) {
            console.error(error);
        },

        get goToPageTitle() {
            return this.isFullScreen
                ? this.currentBuilding?.blockLink?.text ||
                      this.currentBuilding.name
                : "";
        },
    };
};
