import swup from "../swup.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger, SplitText);

export default () => ({
    selectors: {
        inElements: "[data-story-in-animation]",
        imageOut: "[data-story-image-out]",
        splitText: "[data-story-split-text]",
    },
    inElements: null,
    elHeight: 0,
    forceScroll: {
        diff: 0,
        switchAfter: 100,
        isTouching: false,
    },

    init() {
        this.inElements = this.$el.querySelectorAll(this.selectors.inElements);
        this.splitTextEl = this.$el.querySelectorAll(this.selectors.splitText);
        this.imgOutElements = this.$el.querySelectorAll(
            this.selectors.imageOut,
        );

        setTimeout(() => {
            this.setScene();
        }, 1000);

        setTimeout(() => {
            this.setStickButton();
        }, 2000);
    },

    setStickButton() {
        let st = ScrollTrigger.create({
            trigger: this.$el,
            pin: this.$refs.closeButton,
            anticipatePins: 1,
            pinSpacing: false,
            start: "top top",
            end: "bottom top+=120",
        });
    },

    setScene() {
        gsap.set(this.inElements, { opacity: 0 });
        this.initScrollTrigger();
    },

    initScrollTrigger() {
        this.initGsapSplitTextAnimation();
        ScrollTrigger.batch(this.inElements, {
            start: "top bottom-=50",
            once: true,
            onEnter: (batch) => {
                gsap.fromTo(
                    batch,
                    { y: 100 },
                    {
                        opacity: 1,
                        y: 0,
                        stagger: 0.15,
                        duration: 0.9,
                        ease: "circle.out",
                    },
                );
            },
        });
    },

    initGsapSplitTextAnimation() {
        this.splitTextEl.forEach((el) => {
            const splitText = new SplitText(el, { type: "words,chars" });
            const lines = splitText.chars;

            gsap.fromTo(
                lines,
                {
                    "will-change": "transform",
                    transformOrigin: "0% 50%",
                    opacity: 0,
                    y: 10,
                },
                {
                    ease: "power3.in",
                    opacity: 1,
                    y: 0,
                    stagger: 0.005,
                    duration: 0.4,
                    scrollTrigger: {
                        trigger: el,
                        once: true,
                        start: "top bottom-=50",
                    },
                },
            );
        });
    },
});
